import _ from 'lodash';
import queryString from 'query-string';
import {
  PHONE_DUPLICATE_ERROR_MESSAGE,
  EMAIL_DUPLICATE_ERROR_MESSAGE,
} from './constants';

const isClientIdError = (error) => {
  const errorParsed = _.get(error, 'errors.0.params.missingProperty', false);
  return errorParsed === 'clientId';
};

const getEmailLink = (email) => {
  return `<a href=mailto:${email}>${email}</a>`;
};

const styleDuplicateErrorMessage = (error) => {
  return `<div class="duplicate-error">${error}</div>`;
};

const errorCheck = (error = '') => {
  if (!error) {
    return '';
  }
  const isDuplicateError = [
    PHONE_DUPLICATE_ERROR_MESSAGE,
    EMAIL_DUPLICATE_ERROR_MESSAGE,
  ].includes(error);
  const atCharacter = '@';
  const splittedError = error.slice().split(' ');
  const transformed = [];
  splittedError.forEach((word) => {
    if (word.includes(atCharacter)) {
      transformed.push(getEmailLink(word));
    } else {
      transformed.push(word);
    }
  });
  error = transformed.join(' ');
  const href = 'href=';
  if (error.includes(href)) {
    const queryParams = queryString.parse(window.location.search);
    const clientId = _.get(queryParams, 'clientId', '');
    const callbackUri = _.get(queryParams, 'callbackUri', '');

    const splittedError = error.slice().split(href);
    const transformed = [];
    splittedError.forEach((part) => {
      const target = '">';
      if (part.includes(target)) {
        let fullLink;
        if (part.includes('reset-password')) {
          fullLink = `?clientId=${clientId}${target}`;
        } else {
          fullLink = `?clientId=${clientId}&callbackUri=${callbackUri}${target}`;
        }
        const transformedPart = part.replace(target, fullLink);
        transformed.push(transformedPart);
      } else {
        transformed.push(part);
      }
    });
    error = transformed.join(href);
  }
  if (isDuplicateError) {
    error = styleDuplicateErrorMessage(error);
  }
  return error;
};

export const getError = (error, statusCode) => {
  if (isClientIdError(error)) {
    if (error) {
      return error;
    }
    // SHOULD CHANGE THIS MESSAGE WITH CLIENT APPROVAL
    return 'There is a problem with the data you entered, please try again.';
  }
  if (statusCode && statusCode >= 400 && statusCode <= 499) {
    if (error) {
      return errorCheck(error);
    }
    return 'There is a problem with the data you entered, please try again.';

    // return error
  } else if (statusCode && statusCode >= 500 && statusCode <= 599) {
    return 'The system is experiencing a problem, please try again soon.';
  }
  return errorCheck(error) || 'There is a problem with the data you entered, please try again.';
};
