import { createSelector } from 'reselect';
import _ from 'lodash';

const confirmResetPasswordFormSelector = (state) => state.form;

const confirmResetPasswordSelector = createSelector(
  confirmResetPasswordFormSelector,
  (confirmResetPasswordForm) => {
    return _.get(confirmResetPasswordForm, 'confirmResetPassword.values', false);
  },
);

export const getConfirmResetPasswordFormValues = (state) => confirmResetPasswordSelector(state);
