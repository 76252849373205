import _ from 'lodash';
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
} from '../constants';
import { saveUserCredentials } from './../../services/auth';

// parseServerErrors isn't used but I don't want to lose this code as it's not a one-liner,
// and in git, it'll be more difficult to find than when keeping it here
 
const parseServerErrors = (error) => {
  const errorParsed = _.get(error, 'errors.0.params.missingProperty', false);
  if (errorParsed && errorParsed === 'clientId') {
    // CAN UPDATE TO UNIQUE MESSAGING WITH CLIENT APPROVAL
    return 'There is a problem with the data you entered, please try again.';
  }
  return 'There was a problem with your request';
};

const defaultState = {
  isFetching: false,
  isLoggedIn: false,
  loginFailed: false,
  loginSuccess: false,
  statusCode: false,
  errorDetail: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        isFetching: true,
      };
    case LOGIN_USER_SUCCESS:
      const {
        accessToken,
        identityToken,
        refreshToken,
        expiresIn,
      } = action.payload;
      saveUserCredentials({
        accessToken,
        identityToken,
        refreshToken,
        expiresIn,
      });
      return {
        ...state,
        isLoggedIn: true,
        isFetching: false,
        loginSuccess: true,
        loginFailed: false,
      };
    case LOGIN_USER_FAILURE:
      const {
        errorDetail,
        statusCode,
      } = action.payload;
      return {
        ...state,
        isFetching: false,
        loginFailed: true,
        statusCode,
        errorDetail,
      };
    default:
      return { ...state };
  }
};
