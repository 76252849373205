import React, {
  Component,
  Fragment,
} from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import { connect } from 'react-redux';
import { verifyEmail } from '../../redux/actions';

import '../../common/components/Header/Header.css';

const mapStateToProps = (state) => ({
  errorDetail: state.verifyEmailReducer.errorDetail,
  emailVerificationFailed: state.verifyEmailReducer.emailVerificationFailed,
  emailVerificationSuccess: state.verifyEmailReducer.emailVerificationSuccess,
  statusCode: state.verifyEmailReducer.statusCode,
  isVerifying: state.verifyEmailReducer.isVerifying,
  isEmailVerified: state.verifyEmailReducer.isEmailVerified,
});

const mapDispatchToProps = { verifyEmail };

@connect(mapStateToProps, mapDispatchToProps)
class VerifyEmailView extends Component {
  componentDidMount() {
    this.props.verifyEmail();
  }

  render() {
    const {
      isVerifying,
      emailVerificationSuccess,
      emailVerificationFailed,
    } = this.props;
    return (
      <Fragment>
        <div className="header row justify-content-center">
          {isVerifying && <BounceLoader color="#084267" />}
          {!isVerifying && <div className="col-xl-7 col-lg-8 col-md-8 col-sm-10">
            {
              (emailVerificationSuccess || emailVerificationFailed) && <div className="row">
                <div className="col-12 col-md-3 header-image-wrap align-self-center">
                  <img className="header-image" src="https://sso-stage.nylservices.net/images/nylotto-logo.png" />
                </div>
                <div className="col-12 col-md-9 align-self-center">
                  <h5>Congrats!</h5>
                  <p className="header-description">Your email has been verified.</p>
                </div>
              </div>
            }
          </div>}
        </div>
      </Fragment>
    );
  }
}

export default VerifyEmailView;
