import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { getCopy } from '../../lib/copyDefaults';
import './Header.css';

class Header extends PureComponent {
  render() {
    const { pathname } = this.props.location;
    const copy = getCopy(pathname);
    return (
      <div className="header row justify-content-center">
        <div className="col-xl-7 col-lg-8 col-md-8 col-sm-10">
          <div className="row">
            <div className="col-12 col-md-3 header-image-wrap align-self-center">
              { }
              {!(pathname.includes('verify-email')) && <img className="header-image" src="https://sso-stage.nylservices.net/images/nylotto-logo.png" />}
            </div>
            <div className="col-12 col-md-9 align-self-center">
              <h5>{copy.header}</h5>
              <p className="header-description">{copy.body}</p>
              {copy.line2 && <p className="header-description line2">{copy.line2}</p>}
              {
                copy.linkUrl &&
                  <p className="header-link">
                    {copy.preLinkText}
                    <Link
                      to={{
                        pathname: copy.linkUrl,
                        search: window.location.search,
                      }}
                    >
                      {copy.linkText}
                    </Link>
                    {copy.postLinkText}
                  </p>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
