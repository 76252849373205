import React, {
  Component,
  Fragment,
} from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import _ from 'lodash';
import Button from '../../common/components/Button';

import '../../../style/index.css';
import './userDuplicated.css';

const mapStateToProps = (state) => ({ duplicatedEmail: state.registerReducer.duplicatedEmail });

@connect(mapStateToProps, null)
class UserDuplicatedView extends Component {
  render() {
    const { phone, email } = __CONFIG__.contact;
    const queryParams = queryString.parse(window.location.search);
    const clientId = _.get(queryParams, 'clientId', false);
    const resetPasswordLink = `/reset-password?clientId=${clientId}`;
    const loginUrl = `/login?clientId=${clientId}`;
    const { duplicatedEmail } = this.props;
    const maskedEmail = duplicatedEmail;

    return (
      <Fragment>
        <div className="migration-failed row justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-8 col-sm-10">
            <div className="migration-failed-body">
              <h3 className="migration-failed-header">You may already have an account.</h3>
              <p className="migration-failed-paragraph">
                The information you provided matches the registered email:
              </p>
              <p className="migration-failed-paragraph">
                <strong className="masked-email">{maskedEmail}</strong>
              </p>
              <p className="migration-failed-paragraph">
                {   }
                Log on or send a reset password email to this address by clicking one of the buttons below.
              </p>
              <p className="migration-failed-paragraph">
                If you do not recognize this email or need additional assistance,
                you can call us at {phone} or email us at
                {' '}
                <a href={`mailto:${email}`}>
                  {email}
                </a>
                .
              </p>
            </div>
            <div className="button-wrap-duplicated-user">
              <Button
                label="Log in"
                onClickFn={() => {
                  this.props.history.push(loginUrl);
                }}
                className="nyl-button"
              />
              <Button
                label="Reset Password"
                onClickFn={() => {
                  this.props.history.push(resetPasswordLink);
                }}
                className="nyl-button"
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default UserDuplicatedView;
