import {
  put,
  takeLatest,
  call,
} from 'redux-saga/effects';
import _ from 'lodash';
import queryString from 'query-string';
import { geocodeByAddress } from 'react-places-autocomplete';
import { change } from 'redux-form';
import {
  FETCH_ADDRESSES_MIGRATE,
  MIGRATE_PROFILE,
  SELECT_ADDRESS_MIGRATE,
  FETCH_PROFILE_SUCCESS,
} from '../constants';
import {
  migrateProfileSuccess,
  migrateProfileFailure,
  selectAddressMigrateSuccess,
  fetchAddressesFailure,
  fetchAddressesSuccess,
  selectAddressMigrateFailure,
  saveUserProfile,
} from '../actions';
import { PHONE_DUPLICATE_ERROR_MESSAGE } from '../../common/lib/constants';
import {
  fetchUser,
  saveUserName,
} from './../../services/auth';


const validateParsedAddress = (address) => {
  return address && ((address.streetNumber && address.street) || address.premise) && address.city && address.state && address.zip;
};
const parseAddress = (address) => {
  const addressComponents = _.get(address, '0.address_components', false);
  const parsedAddress = {
    streetNumber: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    premise: null,
  };
  if (addressComponents) {
    addressComponents.forEach((component) => {
      if (component.types.includes('street_number')) { // route, locality, administrative_area_level_1, postal_code
        parsedAddress.streetNumber = component.short_name;
      } else if (component.types.includes('route')) {
        parsedAddress.street = component.short_name;
      } else if (component.types.includes('locality')) {
        parsedAddress.city = component.short_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        parsedAddress.state = component.short_name;
      } else if (component.types.includes('postal_code')) {
        parsedAddress.zip = component.short_name;
      } else if (component.types.includes('premise')) {
        parsedAddress.premise = component.premise;
      }
    });
  }
  return parsedAddress;
};

const createMigrateProfileSagas = (api) => {
  function* migrateProfileSaga(action) {
    const {
      migrateProfileForm,
      selectedAddressParsed,
      push,
    } = action.payload;
    try {
      const queryParams = queryString.parse(window.location.search);
      let accessToken = _.get(queryParams, 'accessToken', false);
      const callbackUri = _.get(queryParams, 'callbackUri', false);
      const callbackUriStr = callbackUri ? `?callbackUri=${callbackUri}` : '';
      migrateProfileForm.verify = true;
      const response = yield call(api.migrateProfile, {
        migrateProfileForm,
        selectedAddressParsed,
      });
      const statusCode = _.get(response, 'data.data.statusCode', false);
      const idvPass = _.get(response, 'data.data.pass', false);
      const softFail = _.get(response, 'data.data.softFail', false);
      const hardFail = _.get(response, 'data.data.hardFail', false);
      const phoneDuplicate = _.get(response, 'data.data.phoneDuplicate', false);
      const { refreshToken } = fetchUser();
      const tokenResponse = yield call(api.refreshUserSession, { refreshToken });
      accessToken = _.get(tokenResponse, 'data.data.AccessToken', null);
      const identityToken = _.get(tokenResponse, 'data.data.IdToken', null);
      const expiresIn = _.get(tokenResponse, 'data.data.ExpiresIn', null);
      const userData = yield call(api.fetchProfile);
      const userDataParsed = _.get(userData, 'data.data.attributes', false);

      const { firstName, lastName } = userDataParsed;
      saveUserName({
        firstName,
        lastName,
      });

      const idvFail = softFail || hardFail;
      if (statusCode === 200 && idvPass) {
        yield put(saveUserProfile({ userData: userDataParsed }));
        yield put(migrateProfileSuccess({
          accessToken,
          refreshToken,
          identityToken,
          expiresIn,
          userDataParsed,
        }));
        if (!userDataParsed['custom:phone_verification'] || userDataParsed['custom:phone_verification'] === 'N') {
          push(`/idv/otp${callbackUri ? callbackUriStr : ''}`);
          yield put(migrateProfileSuccess({
            accessToken,
            refreshToken,
            identityToken,
            expiresIn,
            userDataParsed,
          }));
        } else if (userDataParsed['custom:phone_verification'] === 'Y' && (userDataParsed['custom:gov_id_verification'] === '-' && userDataParsed['custom:verified'] === 'N')) {
          push(`/idv/select-gov-id-type${callbackUri ? callbackUriStr : ''}`);
        } else if (userDataParsed['custom:gov_id_verification'] === 'X' && userDataParsed['custom:verified'] === 'N' && userDataParsed['custom:ssn_verification'] === 'N' && userDataParsed['custom:phone_verification'] === 'N') {
          push(`/idv/failed${callbackUri ? callbackUriStr : ''}`);
        } else if (userDataParsed['custom:verified'] === 'Y' || (userDataParsed['custom:verified'] === 'N' && userDataParsed['custom:ssn_verification'] === 'Y' && userDataParsed['custom:phone_verification'] === 'Y') || (userDataParsed['custom:verified'] === 'N' && userDataParsed['custom:ssn_verification'] === 'N' && userDataParsed['custom:gov_id_verification'] === 'Y' && userDataParsed['custom:phone_verification'] === 'Y')) {
          if (callbackUri && refreshToken && identityToken) {
            window.location.replace(`${callbackUri}?accessToken=${accessToken}&refreshToken=${refreshToken}&identityToken=${identityToken}&expiresIn=${expiresIn}`);
          }
        }
      } else if (phoneDuplicate) {
        yield put(migrateProfileFailure(statusCode, PHONE_DUPLICATE_ERROR_MESSAGE));
      } else if (idvFail) {
        // const idvFailData = hardFail ? _.get(response, 'data.data.hardFail', false) : _.get(response, 'data.data', false)
        if (hardFail) {
          push(`/idv/failed${callbackUri ? callbackUriStr : ''}`);
          yield put(migrateProfileFailure());
        } else if (softFail) {
          yield put(saveUserProfile({ userData: userDataParsed }));
          yield put(migrateProfileSuccess({
            accessToken,
            refreshToken,
            identityToken,
            expiresIn,
            userDataParsed,
          }));
          push(`/idv/otp${callbackUri ? callbackUriStr : ''}`);
          yield put(migrateProfileFailure());
        }
      }
    } catch (e) {
      const errorDetail = _.get(e.response, 'data.errors.0.message', false);
      yield put(migrateProfileFailure({ errorDetail }));
    }
  }


  function* watchMigrateProfile() {
    yield takeLatest(MIGRATE_PROFILE, migrateProfileSaga);
  }

  function* fetchAddressesMigrateSaga(action) {
    const { input } = action.payload;
    try {
      const response = yield call(api.fetchPlaces, { input });
      const statusCode = _.get(response, 'status', false);
      if (statusCode === 200) {
        yield put(fetchAddressesSuccess({ response }));
      } else {
        const errorDetail = _.get(response, 'data.errors.0.message', false);
        yield put(fetchAddressesFailure({
          statusCode,
          errorDetail,
        }));
      }
    } catch (e) {
      const errorDetail = _.get(e.response, 'data.errors.0.message', false);
      yield put(fetchAddressesFailure({ errorDetail }));
    }
  }

  function* watchFetchAddressesMigrate() {
    yield takeLatest(FETCH_ADDRESSES_MIGRATE, fetchAddressesMigrateSaga);
  }

  function* selectAddressMigrateSaga(action) {
    const { address } = action.payload;
    try {
      const response = yield call(geocodeByAddress, address);
      const parsedAddress = parseAddress(response);
      if (validateParsedAddress(parsedAddress)) {
        yield put(selectAddressMigrateSuccess({
          address,
          parsedAddress,
        }));
        yield put(change('migrateExistingUser', 'address', address));
      } else {
        yield put(selectAddressMigrateFailure({ error: 'Your address must have a street number, street name, city, state and zipcode. Please try again.' }));
      }
    } catch (error) {
      yield put(selectAddressMigrateFailure({ error }));
    }
  }

  function* watchSelectAddressMigrate() {
    yield takeLatest(SELECT_ADDRESS_MIGRATE, selectAddressMigrateSaga);
  }

  function* fetchProfileSuccessMigrateSaga(action) {
    const { profile } = action.payload;
    const address = _.get(profile, 'address_iso', null);
    if (address) {
      const parsedAddress = address.split('|');
      const streetNumber = _.get(parsedAddress, '0', '');
      const street = _.get(parsedAddress, '1', '');
      const city = _.get(parsedAddress, '2', '');
      const state = _.get(parsedAddress, '3', '');
      const zip = _.get(parsedAddress, '4', '');
      yield put(change('migrateExistingUser', 'streetNumber', streetNumber));
      yield put(change('migrateExistingUser', 'street', street));
      yield put(change('migrateExistingUser', 'city', city));
      yield put(change('migrateExistingUser', 'state', state));
      yield put(change('migrateExistingUser', 'zip', zip));
    }
  }

  function* watchFetchProfileSuccessMigrate() {
    yield takeLatest(FETCH_PROFILE_SUCCESS, fetchProfileSuccessMigrateSaga);
  }

  return {
    watchMigrateProfile,
    watchSelectAddressMigrate,
    watchFetchAddressesMigrate,
    watchFetchProfileSuccessMigrate,
  };
};

export default createMigrateProfileSagas;
