import React, {
  Component,
  Fragment,
} from 'react';
import {
  formValueSelector,
  reduxForm,
} from 'redux-form';
import { connect } from 'react-redux';
import SsnForm from './SsnForm';
import { verifySsn } from '../../redux/actions';

const formSelector = formValueSelector('verify-ssn');


import '../../../style/index.css';
import './verifySsn.css';

const mapStateToProps = (state) => ({
  ssn1: formSelector(state, 'ssn1'),
  ssn2: formSelector(state, 'ssn2'),
  ssn3: formSelector(state, 'ssn3'),
  ssn4: formSelector(state, 'ssn4'),
  verifySsnIsFetching: state.commonReducer.verifySsnIsFetching,
});


const mapDispatchToProps = { verifySsn };

@connect(mapStateToProps, mapDispatchToProps)
class VerifySsnView extends Component {
  constructor(props) {
    super(props);
    this.verifySsnFn = this.verifySsnFn.bind(this);
    this.state = { error: null };
  }
  verifySsnFn(e) {
    e.preventDefault();
    const {
      ssn1,
      ssn2,
      ssn3,
      ssn4,
       
      verifySsn,
      history,
    } = this.props;
    if (ssn1 && ssn2 && ssn3 && ssn4) {
      const ssn = [ssn1, ssn2, ssn3, ssn4].join('');
      verifySsn({
        ssn,
        push: history.push,
      });
    } else {
      this.setState({ error: 'You must enter the last 4 digits of your SSN to proceed' });
    }
  }
  render() {
    const { verifySsnIsFetching } = this.props;
    return (
      <Fragment>
        <div className="verify-ssn row justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-8 col-sm-10">
            <SsnForm
              submitSsnFn={this.verifySsnFn}
              verifySsnIsFetching={verifySsnIsFetching}
            />
            {this.state.error && this.state.error}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default reduxForm({ form: 'verify-ssn' })(VerifySsnView);
