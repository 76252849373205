import _ from 'lodash';
import {
  MIGRATE_PROFILE,
  MIGRATE_PROFILE_SUCCESS,
  MIGRATE_PROFILE_FAILURE,
  FETCH_PROFILE,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  FETCH_ADDRESSES_MIGRATE_SUCCESS,
  RESET_AUTOCOMPLETE_MIGRATE,
  SELECT_ADDRESS_MIGRATE_SUCCESS,
  FETCH_ADDRESSES_MIGRATE_FAILURE,
  SELECT_ADDRESS_MIGRATE_FAILURE,
  FETCH_ADDRESSES_MIGRATE,
} from '../constants';
import { saveUserCredentials } from '../../services/auth';

const defaultState = {
  isFetching: false,
  migrateProfileFailed: false,
  migrateProfileSuccess: false,
  errorDetail: false,
  profile: {},
  fetchProfileFailed: false,
  addressAutocompleteIsFetching: false,
  addresses: [],
  selectedAddress: false,
  selectedAddressParsed: {},
};

// createParsedAddress isn't used but I don't want to lose this code as it's not a one-liner,
// and in git, it'll be more difficult to find than when keeping it here
 
const createParsedAddress = (address) => {
  const addressParts = address.split('|');
  const formattedAddress = {};
  addressParts.forEach((part, i) => {
    if (i === 0) {
      formattedAddress.streetNumber = part;
    } else if (i === 1) {
      formattedAddress.street = part;
    } else if (i === 2) {
      formattedAddress.city = part;
    } else if (i === 3) {
      formattedAddress.state = part;
    } else if (i === 4) {
      formattedAddress.zip = part;
    }
  });

  return formattedAddress;
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case MIGRATE_PROFILE:
      return {
        ...state,
        isFetching: true,
      };
    case MIGRATE_PROFILE_SUCCESS:
      const {
        accessToken,
        identityToken,
        refreshToken,
        expiresIn,
      } = action.payload;
      saveUserCredentials({
        accessToken,
        identityToken,
        refreshToken,
        expiresIn,
      });
      return {
        ...state,
        isFetching: false,
        migrateProfileSuccess: true,
        migrateProfileFailed: false,
        errorDetail: false,
      };
    case MIGRATE_PROFILE_FAILURE:
      const errorDetail = _.get(action.payload, 'errorDetail', false);
      return {
        ...state,
        isFetching: false,
        migrateProfileFailed: true,
        errorDetail,
      };
    case FETCH_PROFILE:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_PROFILE_SUCCESS:
      const { profile } = action.payload;
      return {
        ...state,
        isFetching: false,
        fetchProfileError: true,
        profile,
        // parsedAddress: parsedAddressMigrate,
        // address: (addressIso && addressString) || defaultState.address,
        // selectedAddress: (addressIso && addressString) || defaultState.selectedAddress,
        // selectedAddressParsed: (addressIso && parsedAddressMigrate) || defaultState.selectedAddressParsed,
      };
    case FETCH_PROFILE_FAILURE:
      const errorDetailMessage = _.get(action.payload, 'error.errorDetail', '');
      return {
        ...state,
        isFetching: false,
        fetchProfileFailed: true,
        errorDetail: errorDetailMessage,
      };
    case FETCH_ADDRESSES_MIGRATE:
      return {
        ...state,
        addressAutocompleteIsFetching: true,
      };
    case FETCH_ADDRESSES_MIGRATE_SUCCESS:
      const addresses = _.get(action.payload, 'response', false);
      return {
        ...state,
        addressAutocompleteIsFetching: false,
        addresses,
      };
    case FETCH_ADDRESSES_MIGRATE_FAILURE:
      const addressErrorDetail = _.get(action.payload, 'errorDetail', false);
      return {
        ...state,
        addressAutocompleteIsFetching: false,
        addressErrorDetail,
      };
    case SELECT_ADDRESS_MIGRATE_SUCCESS:
      const {
        address,
        parsedAddress,
      } = action.payload;
      return {
        ...state,
        selectedAddress: address,
        selectedAddressParsed: parsedAddress,
        addressErrorDetail: false,
      };

    case SELECT_ADDRESS_MIGRATE_FAILURE:
      const { error } = action.payload;
      return {
        ...state,
        addressErrorDetail: error,
        selectedAddress: false,
        selectedAddressParsed: {},
      };
    case RESET_AUTOCOMPLETE_MIGRATE:
      return {
        ...state,
        selectedAddress: false,
        selectedAddressParsed: {},
      };
    default:
      return { ...state };
  }
};
