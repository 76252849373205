import React, {
  Component,
  Fragment,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { getLoginFormValues } from '../../redux/selectors/loginFormSelector';
import { loginUser } from '../../redux/actions';
import {
  email,
  required,
} from '../../common/lib/validation';
import LoginForm from './LoginForm';

import '../../../style/index.css';
import './login.css';

const mapStateToProps = (state) => ({
  loginForm: getLoginFormValues(state),
  loginFailed: state.loginReducer.loginFailed,
  loginSuccess: state.loginReducer.loginSuccess,
  statusCode: state.loginReducer.statusCode,
  errorDetail: state.loginReducer.errorDetail,
  isFetching: state.loginReducer.isFetching,
});

const mapDispatchToProps = { loginUser };

const isValid = (values) => {
  let valid = true;
  if (email(values.email)
    || required(values.password)) {
    valid = false;
  }
  return valid;
};

@connect(mapStateToProps, mapDispatchToProps)
class LoginView extends Component {
  static propTypes = {
    loginForm: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool,
    ]),
    loginUser: PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
    this.state = { formSubmissionError: false };
  }

  handleLoginSubmit(e) {
    e.preventDefault();
    const {
      loginForm,
       
      loginUser,
      history,
    } = this.props;
    if (isValid(loginForm)) {
      loginUser(loginForm, history.push);
      this.setState({ formSubmissionError: false });
    } else {
      this.setState({ formSubmissionError: true });
    }
  }

  render() {
    const {
      loginSuccess,
      loginFailed,
      statusCode,
      errorDetail,
      isFetching,
    } = this.props;
    const { formSubmissionError } = this.state;
    return (
      <Fragment>
        <div className="login row justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-8 col-sm-10">
            <LoginForm
              submitFormFn={this.handleLoginSubmit}
              loginSuccess={loginSuccess}
              loginFailed={loginFailed}
              formSubmissionError={formSubmissionError}
              statusCode={statusCode}
              errorDetail={errorDetail}
              isFetching={isFetching}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default reduxForm({ form: 'login' })(LoginView);
