 
import React, {
  Component,
  Fragment,
} from 'react';
import { Field } from 'redux-form';
import RenderField from '../../common/components/Form/RenderField';
import Button from '../../common/components/Button';
import {
  validatePassword,
  isEmpty,
} from '../../common/lib/validation';
import { getError } from '../../common/lib/getError';

import '../../../style/index.css';
import './confirmResetPassword.css';

class ConfirmResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.confirmPassword = this.confirmPassword.bind(this);
  }

  confirmPassword(value) {
    const { password } = this.props;
    if (!isEmpty(value) && value !== password) {
      return 'Passwords must match.';
    }
  }

  render() {
    const {
      submitFormFn,
      confirmResetPasswordFailed,
      statusCode,
      errorDetail,
      formSubmissionError,
      submitting,
    } = this.props;
    return (
      <Fragment>
        <form
          className="needs-validation"
          noValidate
        >
          <div className="personal-info-wrap">
            <Field
              name="password"
              type="password"
              component={RenderField}
              label="New Password"
              validate={validatePassword}
              helpText="Your password must be 8–20 characters long, contain letters and numbers, and must not contain spaces, special characters, or emoji."
            />
            <Field
              name="confirmPassword"
              type="password"
              component={RenderField}
              label="Confirm New Password"
              validate={this.confirmPassword}
            />
            <div className="button-wrap">
              <Button
                label="Reset Password"
                onClickFn={submitFormFn}
                className={`nyl-button ${submitting ? 'disabled' : ''}`}
              />
              {confirmResetPasswordFailed &&
                <p className="submit-error" dangerouslySetInnerHTML={{ __html: getError(errorDetail, statusCode) }} />
              }
              {formSubmissionError &&
                <p className="submit-error">All inputs must be valid in order to submit the form.</p>
              }
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}

export default ConfirmResetPasswordForm;
