import { createSelector } from 'reselect';
import _ from 'lodash';

const registerFormSelector = (state) => state.form;

const registerSelector = createSelector(
  registerFormSelector,
  (registerForm) => {
    return _.get(registerForm, 'register.values', false);
  },
);

export const getRegisterFormValues = (state) => registerSelector(state);
