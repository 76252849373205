import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import uuid from 'uuid/v4';
import { fetchUser } from './auth';

const constructBaseUrl = () => {
  return __CONFIG__.apiBaseUrl;
};

const generateGoogleApiSessionToken = () => {
  return uuid();
};

const baseUrl = constructBaseUrl();

const instantiateAxios = (baseURL) => {
  const headers = { 'Content-Type': 'application/json' };
  return axios.create({
    baseURL,
    headers,
    timeout: 30000,
    crossDomain: true,
    withCredentials: false,
    validateStatus: () => true,
  });
};

const fetchUrlParams = () => {
  const queryParams = queryString.parse(window.location.search);
  return {
    clientId: _.get(queryParams, 'clientId', false),
    secretHash: _.get(queryParams, 'secretHash', false),
  };
};

const create = () => {
  const axiosInstance = instantiateAxios(baseUrl);
  const googlePlacesApiInstance = instantiateAxios(__CONFIG__.googlePlacesApiBaseUrl);
  const registerUser = ({ registerUserForm }) => {
    const user = Object.assign({}, registerUserForm);
    const newsletterOptIn = _.get(registerUserForm, 'newsletter', false);
    // const referrer = callbackUri;
    user.phone = user.phone.replace(/[^\d]/g, '');
    delete user.acceptedTermsAndConditions;
    delete user.confirmPassword;
    delete user.isOver18;
    delete user.newsletter;
    if (user.middleName) {
      delete user.middleName;
    }

    if (user.noSsn4 && user.ssn4) {
      delete user.ssn4;
    }

    if (user.suffix) {
      delete user.suffix;
    }
    user.birthdate = moment(user.birthdate).format('MM/DD/YYYY');
    user.newsletterOptIn = newsletterOptIn;

    // this is not used by the api and causes a schema error
    // if (referrer) {
    //   user.referrer = referrer;
    // }

    // IDENTIFIERS VIA 3rd PARTY
    const urlParams = fetchUrlParams();
    if (urlParams.clientId && urlParams.secretHash) {
      user.clientId = urlParams.clientId;
      user.secretHash = urlParams.secretHash;
    } else if (urlParams.clientId) {
      user.clientId = urlParams.clientId;
    }
    return axiosInstance.post('/sso/register-verify', user);
  };

  const loginUser = ({ loginUserForm }) => {
    const user = Object.assign({}, loginUserForm);
    // IDENTIFIERS VIA 3rd PARTY
    const urlParams = fetchUrlParams();
    if (urlParams.clientId && urlParams.secretHash) {
      user.clientId = urlParams.clientId;
      user.secretHash = urlParams.secretHash;
    } else if (urlParams.clientId) {
      user.clientId = urlParams.clientId;
    }
    return axiosInstance.post('/sso/login', user);
  };

   
  const refreshUserSession = ({ refreshToken }) => {
    const urlParams = fetchUrlParams();
    const clientId = _.get(urlParams, 'clientId', false);
    if (clientId) {
      return axiosInstance.post('/sso/refresh-token', {
        clientId: urlParams.clientId,
        refreshToken,
      }, { headers: { 'X-API-Key': __CONFIG__.apiKey } });
    }
  };

  const requestOtp = ({ type }) => {
    const user = fetchUser();
    return axiosInstance.post('/sso/phone-code-gen', { type }, { headers: { Authorization: user.accessToken } });
  };

  const verifyOtp = ({
    asi, pin, type,
  }) => {
    const payload = {
      phoneConfirmation: {
        asi,
        pincode: pin,
        pinType: type,
      },
    };
    const user = fetchUser();
    return axiosInstance.put('/sso/register-verify', payload, { headers: { Authorization: user.accessToken } });
  };

  const verifySsn = ({ ssn }) => {
    const payload = { ssnConfirmation: { ssn } };
    const user = fetchUser();
    return axiosInstance.put('/sso/register-verify', payload, { headers: { Authorization: user.accessToken } });
  };

  const resetPassword = ({ forgotPasswordForm }) => {
    const resetPasswordPayload = Object.assign({}, forgotPasswordForm);
    // IDENTIFIERS VIA 3rd PARTY
    const urlParams = fetchUrlParams();
    if (urlParams.clientId && urlParams.secretHash) {
      resetPasswordPayload.clientId = urlParams.clientId;
      resetPasswordPayload.secretHash = urlParams.secretHash;
    } else if (urlParams.clientId) {
      resetPasswordPayload.clientId = urlParams.clientId;
    }
    return axiosInstance.post('/sso/reset-password', resetPasswordPayload);
  };

  const confirmResetPassword = ({
    confirmResetPasswordForm,
    clientId,
    secret,
    email,
    code,
  }) => {
    const confirmResetPasswordPayload = Object.assign({}, confirmResetPasswordForm);
    confirmResetPasswordPayload.clientId = clientId;
    confirmResetPasswordPayload.secret = secret;
    confirmResetPasswordPayload.email = email;
    confirmResetPasswordPayload.code = code;
    delete confirmResetPasswordPayload.confirmPassword;
    return axiosInstance.post('/sso/confirm-reset-password', confirmResetPasswordPayload);
  };

  const verifyEmail = ({
    clientId,
    secret,
    email,
    code,
  }) => {
    const verifyEmailPayload = {
      clientId,
      secret,
      email,
      code,
    };

    return axiosInstance.put('/sso/email-verify', verifyEmailPayload);
  };

  const fetchProfile = (accessToken = false) => {
    const user = fetchUser();
    return axiosInstance.get('/users', { headers: { Authorization: accessToken || user.accessToken } });
  };

  const migrateProfile = ({ migrateProfileForm }) => {
    const user = fetchUser();
    const birthdateRaw = _.get(migrateProfileForm, 'birthdate', false);
    const migrateProfileTrimmed = {
      firstName: _.get(migrateProfileForm, 'firstName', null),
      lastName: _.get(migrateProfileForm, 'lastName', null),
      streetNumber: _.get(migrateProfileForm, 'streetNumber', null),
      street: _.get(migrateProfileForm, 'street', null),
      addressLine2: _.get(migrateProfileForm, 'addressLine2', null),
      city: _.get(migrateProfileForm, 'city', null),
      state: _.get(migrateProfileForm, 'state', null),
      zip: _.get(migrateProfileForm, 'zip', null),
      phone: migrateProfileForm.phone.replace(/[^\d]/g, ''),
      email: _.get(migrateProfileForm, 'email', null),
      verify: _.get(migrateProfileForm, 'verify', true),
      birthdate: birthdateRaw && moment(birthdateRaw).format('MM/DD/YYYY'),
      ssn4: _.get(migrateProfileForm, 'ssn4', false),
      noSsn4: _.get(migrateProfileForm, 'noSsn4', false),
    };

    if (migrateProfileTrimmed.noSsn4 && migrateProfileTrimmed.ssn4) {
      delete user.ssn4;
    }

    return axiosInstance.put('/sso/register-verify', { profileUpdate: migrateProfileTrimmed }, { headers: { Authorization: user.accessToken } });
  };

  const signOut = ({ accessToken }) => {
    let user;
    let accessTokenFetched;
    if (!accessToken) {
      user = fetchUser();
      accessTokenFetched = user.accessToken;
    }

    const signOutData = { accessToken: accessToken || accessTokenFetched };

    const urlParams = fetchUrlParams();
    if (urlParams.clientId) {
      signOutData.clientId = urlParams.clientId;
    }
    return axiosInstance.post('/sso/logout', signOutData, { headers: { Authorization: accessToken || accessTokenFetched } });
  };

  const updateProfile = ({ updateProfileForm, accessToken }) => {
    let user;
    let accessTokenFetched;
    if (!accessToken) {
      user = fetchUser();
      accessTokenFetched = user.accessToken;
    }

    const birthdateRaw = _.get(updateProfileForm, 'birthdate', false);
    const updateProfileTrimmed = {
      firstName: _.get(updateProfileForm, 'firstName', null),
      lastName: _.get(updateProfileForm, 'lastName', null),
      streetNumber: _.get(updateProfileForm, 'streetNumber', null),
      street: _.get(updateProfileForm, 'street', null),
      addressLine2: _.get(updateProfileForm, 'addressLine2', null),
      city: _.get(updateProfileForm, 'city', null),
      state: _.get(updateProfileForm, 'state', null),
      zip: _.get(updateProfileForm, 'zip', null),
      phone: updateProfileForm.phone.replace(/[^\d]/g, ''),
      email: _.get(updateProfileForm, 'email', null),
      verify: true,
      birthdate: birthdateRaw && moment(birthdateRaw).format('MM/DD/YYYY'),
    };

    if (updateProfileForm.password) {
      updateProfileTrimmed.password = updateProfileForm.password;
    }

    // IDENTIFIERS VIA 3rd PARTY
    const urlParams = fetchUrlParams();
    if (urlParams.clientId && urlParams.secretHash) {
      updateProfileTrimmed.clientId = urlParams.clientId;
      updateProfileTrimmed.secretHash = urlParams.secretHash;
    } else if (urlParams.clientId) {
      updateProfileTrimmed.clientId = urlParams.clientId;
    }
    return axiosInstance.put('/sso/register-verify', { profileUpdate: updateProfileTrimmed }, { headers: { Authorization: accessToken || accessTokenFetched } });
  };

  const fetchPlaces = ({ input }) => {
    console.log('input in api call', input);
    return googlePlacesApiInstance.get('/json', {
      params: {
        input,
        key: __CONFIG__.googleApiKey,
        sessiontoken: generateGoogleApiSessionToken(),
        types: 'address',
        components: 'country:us',
      },
    });
  };

  const fetchGovIdResponse = ({
    code, govIdType, accessToken,
  }) => {
    let accessTokenFetched;
    let user;
    if (!accessToken) {
      user = fetchUser();
      accessTokenFetched = user.accessToken;
    }
    return axiosInstance.put('/sso/handle-govid-response', {
      code,
      govIdType,
    }, { headers: { Authorization: accessToken || accessTokenFetched } });
  };

  return ({
    registerUser,
    loginUser,
    resetPassword,
    confirmResetPassword,
    verifyEmail,
    fetchProfile,
    signOut,
    updateProfile,
    fetchPlaces,
    requestOtp,
    verifyOtp,
    verifySsn,
    refreshUserSession,
    migrateProfile,
    fetchGovIdResponse,
  });
};

export default { create };
