import {
  GET_FORGOT_PASSWORD_CODE,
  GET_FORGOT_PASSWORD_CODE_SUCCESS,
  GET_FORGOT_PASSWORD_CODE_FAILURE,
} from '../constants';

const defaultState = {
  isFetching: false,
  getForgotPasswordCodeFailed: false,
  getForgotPasswordCodeSuccess: false,
  errorDetail: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_FORGOT_PASSWORD_CODE:
      return {
        ...state,
        isFetching: true,
        getForgotPasswordCodeFailed: false,
        getForgotPasswordCodeSuccess: false,
      };
    case GET_FORGOT_PASSWORD_CODE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        getForgotPasswordCodeSuccess: true,
        getForgotPasswordCodeFailed: false,
      };
    case GET_FORGOT_PASSWORD_CODE_FAILURE:
      const {
        errorDetail,
        statusCode,
      } = action.payload;
      return {
        ...state,
        isFetching: false,
        getForgotPasswordCodeFailed: true,
        errorDetail,
        statusCode,
      };
    default:
      return { ...state };
  }
};
