import { createSelector } from 'reselect';
import _ from 'lodash';

const loginFormSelector = (state) => state.form;

const loginSelector = createSelector(
  loginFormSelector,
  (loginForm) => {
    return _.get(loginForm, 'login.values', false);
  },
);

export const getLoginFormValues = (state) => loginSelector(state);
