import React, { Fragment } from 'react';
import Button from '../../common/components/Button';

import '../../../style/index.css';
import './migrationFailed.css';

const MigrationFailedView = () => {
  const { email } = __CONFIG__.contact;
  const subjectMessage = 'Unverified Identity';
  const subject = subjectMessage.replace(' ', '%20');
  const emailTemplate = `mailto:${email}?&subject=${subject}`;
  return (
    <Fragment>
      <div className="migration-failed row justify-content-center">
        <div className="col-xl-7 col-lg-8 col-md-8 col-sm-10">
          <div className="migration-failed-body">
            <h3 className="migration-failed-header">Sorry, we cannot verify your identity.</h3>
            <p className="migration-failed-paragraph">
              { }
              We are unable to verify your identity using the information you've provided. You may try again by logging in using the same account credentials you just created. For additional assistance, please email us at
              {' '}
              <a href={`mailto:${email}`}>
                {email}
              </a>
              .
            </p>
          </div>
          <div className="button-wrap">
            <Button
              label="Contact Us"
              onClickFn={() => location.href = emailTemplate}
              className="nyl-button"
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MigrationFailedView;
