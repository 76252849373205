import React, {
  Component,
  Fragment,
} from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import _ from 'lodash';
import { fetchGovIdResponse } from '../../redux/actions';

import '../../../style/index.css';
import './govIdResponse.css';

const mapStateToProps = () => ({});

const mapDispatchToProps = { fetchGovIdResponse };

@connect(mapStateToProps, mapDispatchToProps)
class GovIdResponseView extends Component {
  componentDidMount() {
    const {
       
      fetchGovIdResponse,
      history,
    } = this.props;
    const queryParams = queryString.parse(window.location.search);
    const code = _.get(queryParams, 'code', false);
    fetchGovIdResponse(code, history.push);
  }

  render() {
    return (
      <Fragment>
        <div className="gov-id-response row justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-8 col-sm-10">
            <p className="processing-message">Please wait. Processing GovID response...</p>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default GovIdResponseView;

