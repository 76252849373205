import _ from 'lodash';
import { saveUserCredentials } from './../../services/auth';
import {
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  FETCH_ADDRESSES,
  FETCH_ADDRESSES_SUCCESS,
  FETCH_ADDRESSES_FAILURE,
  SELECT_ADDRESS_SUCCESS,
  SELECT_ADDRESS_FAILURE,
  RESET_AUTOCOMPLETE,
} from '../constants';

const defaultState = {
  isFetching: false,
  addressAutocompleteIsFetching: false,
  addresses: [],
  selectedAddress: false,
  selectedAddressParsed: {},
  isRegistered: false,
  registrationFailed: false,
  registrationSuccess: false,
  addressErrorDetail: false,
  duplicatedEmail: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      return {
        ...state,
        isFetching: true,
      };
    case REGISTER_USER_SUCCESS:
      const {
        accessToken,
        identityToken,
        refreshToken,
        expiresIn,
      } = action.payload;
      saveUserCredentials({
        accessToken,
        identityToken,
        refreshToken,
        expiresIn,
      });
      return {
        ...state,
        isRegistered: true,
        isFetching: false,
        registrationSuccess: true,
        registrationFailed: false,
        errorDetail: false,
        selectedAddress: false,
      };
    case REGISTER_USER_FAILURE:
      const errorDetail = _.get(action.payload, 'errorDetail', false);
      const statusCode = _.get(action.payload, 'statusCode', false);
      const duplicatedEmail = _.get(action.payload, 'duplicatedEmail', false);
      return {
        ...state,
        isFetching: false,
        registrationFailed: true,
        errorDetail,
        statusCode,
        duplicatedEmail,
      };
    case FETCH_ADDRESSES:
      return {
        ...state,
        addressAutocompleteIsFetching: true,
      };
    case FETCH_ADDRESSES_SUCCESS:
      const addresses = _.get(action.payload, 'response', false);
      return {
        ...state,
        addressAutocompleteIsFetching: false,
        addresses,
      };
    case FETCH_ADDRESSES_FAILURE:
      const addressErrorDetail = _.get(action.payload, 'errorDetail', false);
      return {
        ...state,
        addressAutocompleteIsFetching: false,
        addressErrorDetail,
      };
    case SELECT_ADDRESS_SUCCESS:
      const {
        address,
        parsedAddress,
      } = action.payload;
      return {
        ...state,
        selectedAddress: address,
        selectedAddressParsed: parsedAddress,
        addressErrorDetail: false,
      };

    case SELECT_ADDRESS_FAILURE:
      const { error } = action.payload;
      return {
        ...state,
        addressErrorDetail: error,
        selectedAddress: false,
        selectedAddressParsed: {},
      };
    case RESET_AUTOCOMPLETE:
      return {
        ...state,
        selectedAddress: false,
        selectedAddressParsed: {},
      };
    default:
      return { ...state };
  }
};
