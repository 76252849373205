import { createSelector } from 'reselect';
import _ from 'lodash';

const updateProfileFormSelector = (state) => state.form;

const updateProfileSelector = createSelector(
  updateProfileFormSelector,
  (updateProfileForm) => {
    return _.get(updateProfileForm, 'updateProfile.values', false);
  },
);

export const getUpdateProfileFormValues = (state) => updateProfileSelector(state);
